<template>
    <div>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item to="/income">我的收益</el-breadcrumb-item>
        <el-breadcrumb-item :to="'/bill/'+$route.params.id">申请提现</el-breadcrumb-item>
        <el-breadcrumb-item>上传发票</el-breadcrumb-item>
      </el-breadcrumb>
      <el-divider class="div_der"></el-divider>
      <div class="header">
        <ul>
          <li class="title">提现金额</li>
          <li>
            <div class="money">
              <span>{{$toFindexFn(appIncomeDetailsEntity.totalMoney)}}</span>元
            </div>
            <div class="taxation">
              (税费: {{$toFindexFn(appIncomeDetailsEntity.faxMoney)}} 应到账金额:<span>{{$toFindexFn(appIncomeDetailsEntity.money)}}</span>)
            </div>
          </li>
        </ul>
        <ul>
          <li class="title">提现银行</li>
          <li>
            提现至
            <img :src="headerImg || BankDefault">
            <span class="newsCard">{{card.bankName}} (****{{card.bankCard !==undefined?card.bankCard.substring(card.bankCard.length-4):''}})</span>
            <span class="modify" @click="$router.push('/setbankcard/Msmchecking'+ $store.state.usermsg.webLoginType)">修改</span>
          </li>
        </ul>
      </div>
      <div class="cotent">
        <p class="title1">上传账单发票</p>
        <p class="title2">{{$store.state.user.webLoginType===0?'金额在500元及以下无需发票':''}}</p>
      </div>
      <CashOutTable ref="CashOutTable"></CashOutTable>
    </div>
</template>

<script>
import CashOutTable from './components/CashOutTable'
import  BankDefault from  '../../assets/income/BankDefault.png'

/***
 * 发票管理（确认提现）
 *
 */
import {myBankMsg,incomeDetailV2} from '../../api/income'
import {dictInfo} from "@/api/dictionary";
export default {
  name: "Cash-out",
  components: {CashOutTable},
  //组件数据传参
  provide () {
    return {
      parent: this
    }
  },
  created() {
    this.myBankMsg_details()
    this.incomeDetailV2_details()
  },
  mounted() {
  },
  data(){
    return {
      card:{},
      appIncomeDetailsEntity:{},
      headerImg:'',
      BankDefault
    }
  },
  methods:{
    async myBankMsg_details(){
      let  res=await myBankMsg()
      this.card=res.data;
      this.dictInfoFn()
    },
    // 字典
    dictInfoFn(){
      dictInfo({codes:['10014']}).then(res=>{

    let { remarks } = res.data.find(i => {
          return i.label === this.card.bankName
        })
        this.headerImg = remarks
      })
    },
    //接口普数据
    async incomeDetailV2_details(){
      let res=await incomeDetailV2({id:this.$route.params.id})
      if(res.code===0){
        this.appIncomeDetailsEntity=res.data.appIncomeDetailsEntity;
        res.data.list.forEach(item=>{
            item.invoice=[]
            if(item.totalMoney<=500){
               item.radio="3"
            }else {
              item.radio="1"
            }
        })
        this.$refs.CashOutTable.tableData=res.data.list;
        this.$refs.CashOutTable.appIncomeDetailsEntity=res.data.appIncomeDetailsEntity;
      }
    }
  },
}
</script>

<style scoped lang="scss">
@import "../../styles/works.scss";

.div_der {
  margin-top: 20px;
  margin-bottom: 40px;
}

.header {
  height: 210px;
  border: 1px solid #f4f4f4;
  padding: 24px 0 0 30px;
  box-sizing: border-box;

  > ul {
    list-style: none;
    padding: 0;
    margin: 0;

    > .title {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      color: #555555;
      margin-bottom: 15px;
    }

    .money {
      font-size: 10px;
          display: inline-block;
      margin-right: 16px;
      span {
        font-size: 30px;
        font-family: PingFang SC, PingFang SC-Semibold;
        font-weight: 600;
        text-align: LEFT;
        color: #333333;
      }
    }
    .taxation{
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #999999;
      display: inline-block;
      span{
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
      }
    }
  }
  ul:nth-child(2){
    margin-top: 20px;
    li:nth-child(2){
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #666666;

      img{
        margin-left: 17px;
        margin-right: 8px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        vertical-align: middle;
      }
      .newsCard{
        color: #333333;

      }
      .modify{
        color: #ff004d;
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }

}
.cotent{
  margin-top: 40px;
  margin-bottom: 30px;
  p{
    margin: 0;
    padding: 0;
  }
  .title1{
    font-size: 18px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    text-align: LEFT;
    color: #333333;
    margin-bottom: 9px;
  }
  .title2{
    font-size: 14px;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #666666;
  }
}

</style>
