<template>
  <el-dialog
      title="发票抬头"
      center
      :visible.sync="dialogVisible"
     >
    <ul class="content">
      <p class="title">付款公司发票信息</p>
      <li>
        <span class="label">单位名称：</span>
        <span class="con">{{InvoiceHeader.receiptName}}</span>
      </li>
      <li>
        <span class="label">税号：</span>
        <span class="con">{{InvoiceHeader.receiptNo}}</span>
      </li>
      <li>
        <span class="label">单位地址：</span>
        <span class="con">{{InvoiceHeader.receiptAddress}}</span>
      </li>
      <li>
        <span class="label">电话号码：</span>
        <span class="con">{{InvoiceHeader.receiptTel}}</span>
      </li>
      <li>
        <span class="label">开户银行：</span>
        <span class="con">{{InvoiceHeader.receiptBank}}</span>
      </li>
      <li>
        <span class="label">银行账户：</span>
        <span class="con">{{InvoiceHeader.receiptBankNo}}</span>
      </li>
      <li>
        <span class="label">发票类型：</span>
        <span class="con">{{InvoiceHeader.receiptType===1?'增值税':InvoiceHeader.receiptType===0?'普通':''}}</span>
      </li>
    </ul>
    <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button class="but" type="primary"   :data-clipboard-text="copyData" >复 制</el-button>
  </span>
  </el-dialog>
</template>

<script>
import Clipboard from "clipboard";
export default {
  name: "InvoiceHeader",
  data() {
    return {
      dialogVisible: false,
      clipboard:'',
      InvoiceHeader:{},
      copyData:''
    }
  },
  mounted() {
    this.copy()
    //     this.copyData = `
    // 单位名称  ${this.InvoiceHeader ? this.InvoiceHeader.receiptName : ''}
    // 税号  ${this.InvoiceHeader ? this.InvoiceHeader.receiptNo : ''}
    // 单位地址  ${this.InvoiceHeader ? this.InvoiceHeader.receiptAddress : ''}
    // 电话号码  ${this.InvoiceHeader ? this.InvoiceHeader.receiptTel : ''}
    // 开户银行  ${this.InvoiceHeader ? this.InvoiceHeader.receiptBank : ''}
    // 银行账户  ${this.InvoiceHeader ? this.InvoiceHeader.receiptBankNo : ''}
    // 发票类型  ${this.InvoiceHeader ? (this.InvoiceHeader.receiptType === 1 ? '增值税发票' : '普通发票') : ''}
    // `
  },
  methods:{
    copy(){
       this.clipboard=new Clipboard('.but');
        this.clipboard.on('success',()=>{
        console.log('复制成功')
        this.$popup({msg:'复制成功~'})
      })
          this.clipboard.on('error',()=>{
            console.log('复制失败')
          })
    }
  },
  watch:{
    InvoiceHeader:{
      deep:true,
      handler:function (val){
        this.InvoiceHeader = val;
        console.log(this.InvoiceHeader )
        this.copyData = `
单位名称  ${this.InvoiceHeader.receiptName  ? this.InvoiceHeader.receiptName : ''}
税号  ${this.InvoiceHeader.receiptName  ? this.InvoiceHeader.receiptNo : ''}
单位地址  ${this.InvoiceHeader.receiptName  ? this.InvoiceHeader.receiptAddress : ''}
电话号码  ${this.InvoiceHeader.receiptName  ? this.InvoiceHeader.receiptTel : ''}
开户银行  ${this.InvoiceHeader.receiptName ? this.InvoiceHeader.receiptBank : ''}
银行账户  ${this.InvoiceHeader.receiptName ? this.InvoiceHeader.receiptBankNo : ''}
发票类型  ${this.InvoiceHeader.receiptName ? (this.InvoiceHeader.receiptType === 1 ? '增值税发票' : '普通发票') : ''}
`
      },
    }
  },
  beforeDestroy () {
    if(this.clipboard!==''){
      this.clipboard.destroy()
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-dialog {
  width: 480px;

  .el-dialog__header {
    padding: 30px 0 0 0;
    margin: 0;

    .el-dialog__title {
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      color: #222222;
    }
  }

  .el-dialog__body {
    padding: 0 32px;
    .content {
      margin-top: 30px;
      margin-bottom: 20px;
      background: #f7f7f7;
      height: 334px;
      padding: 30px 0 24px 24px;
      list-style: none;
      .title{
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
        margin: 0 0 30px 0;
      }
      >li{
        margin-bottom:24px;
      }
      .label{
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #999999;
        line-height: 14px;
        width: 56px;
        maggin-right:20px
      }
      .content{
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #999999;
      }
    }

  }

  .dialog-footer {
    .el-button {
      width: 184px;
      height: 40px;
      background: #f4f4f4;
      border-radius: 3px;
    }
    .but{
      background: #ff004d;
    }

  }
}
</style>
