<template>
  <div class="big">
    <el-dialog
        :visible.sync="dialogVisible"
        width="480px"
    >
      <div slot="title" class="header-title">
        {{ titleName }}
      </div>
      <div v-if="paymentStatus===0" class="pay-conter_0">
        您还未设置支付密码，设置后即可提现~
      </div>
      <div v-if="paymentStatus===1" class="pay-conter_1">
        <ul class="pay">
          <li>
            <div>
              <span>提现金额</span>
              <span class="money">￥{{$toFindexFn(appIncomeDetailsEntity.totalMoney) }}</span>
            </div>
            <div>
              <span>税费{{ $toFindexFn(appIncomeDetailsEntity.faxMoney) }}</span>
            </div>
          </li>
          <li>
            <el-input show-password v-model="CashOut" placeholder="输入您设置的支付密码">
            </el-input>
          </li>
          <li style="margin-top: 12px;color:#FF004D;font-size: 12px; text-align: right"><span style="cursor: pointer" @click="paymentStatus=2">忘记密码</span>
          </li>
        </ul>
      </div>
      <div v-if="paymentStatus===2" class="pay-conter_2">
        <div>
          <el-input placeholder="请输入内容" v-model="input3" readonly class="input-with-select">
            <span slot="prefix" class="prefix">+{{ userMsg.zone }}</span>
          </el-input>
        </div>
        <div>
          <el-input placeholder="短信验证码" v-model="input4"  >
            <span slot="suffix" class="suffix" @click="SMSverification()">{{ MsM }}</span>
          </el-input>
        </div>
      </div>
      <div v-if="paymentStatus===3" class="pay-conter_3">
        <p class="title">设置新的支付密码</p>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
          <el-form-item prop="newPas1">
            <el-input show-password v-model="ruleForm.newPas1" placeholder="请设置支付密码，须为6位纯数字" maxlength="6"></el-input>
          </el-form-item>
          <el-form-item prop="newPas2">
            <el-input show-password v-model="ruleForm.newPas2" placeholder="短信验证码请再次输入设置的支付密码" maxlength="6"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="footer-but">
        <el-button @click="dialogVisible=false">取消</el-button>
        <el-button @click="submit()" class="but">{{ describe }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {sms_send, sms_validate} from "../../../api";
import {payPwdUpdate, withdrawV2} from "../../../api/income";
import md5 from 'md5'
export default {
  name: "Payment.vue",
  props: ['userMsg', 'appIncomeDetailsEntity'],
  inject: ['parent'],
  data() {
    return {
      dialogVisible: false,
      paymentStatus: 1,
      input3: '',
      input4: '',
      ruleForm: {
        newPas1: '',
        newPas2: ''
      },
      //提现密码
      CashOut:'',
      rules: {
        newPas1: {
          required: true, validator: (rule, value, callback) => {
            let regex = /^\d{6}$/;
            if (regex.test(value)) {
              new callback()
            } else {
              new callback('密码须为6位纯数字')
            }
          }, trigger: 'blur'
        },
        newPas2: {
          required: true, validator: (rule, value, callback) => {
            if (this.ruleForm.newPas1 === value) {
              new callback()
            } else {
              new callback('两次填写密码不一致')
            }
          }, trigger: 'blur'
        }
      },
      MsM: '获取验证码',
      noteBol: false
    }
  },
  computed: {
    titleName() {
      let title = ""
      switch (this.paymentStatus) {
        case 0:
          title = '提示'
          break;
        case 1:
          title = '输入支付密码'
          break;
        case 2:
          title = '安全验证'
          break;
        case 3:
          title = '设置支付密码'
      }
      return title
    },
    describe() {
      switch (this.paymentStatus) {
        case 0:
          return '设置支付密码'
        default:
          return '确定'
      }
    }
  },
  methods: {
    //发送短信
    async SMSverification() {
      if (this.noteBol) {
        return
      }
      this.noteBol = true
      let p = {
        phone: this.userMsg.mobile,
        zone: this.userMsg.zone,
        type: 5,
        cs: Date.parse(new Date())
      }
      let res = await sms_send(p)
      if (res.code === 0) {
        this.MsM = '60s'
        let timer
        clearInterval(timer)
        let that = this
        let num = 60
        timer = setInterval(function () {
          num--
          that.MsM = num + 's'
          if (num <= 0) {
            clearInterval(timer)
            that.MsM = '获取短信验证'
            that.noteBol = false
          }
        }, 1000)
      } else {
        this.noteBol = false;
      }
    },
    //提交短信
    async SubmitSMS(){
       let p={
         zone:this.userMsg.zone ,
         phone: this.userMsg.mobile,
         type: 5,
         acode: this.input4
       }
       let res = await sms_validate(p)
       if(res.httpStatus!==undefined){
         this.paymentStatus=3
         this.input4=''
       }
    },
    //修改密码
     undataPassword(){
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          let p = {
            newPwd: md5(this.ruleForm.newPas1)
          }
          let res = await payPwdUpdate(p);
          if(res.code===0){
            this.paymentStatus=1
            this.$refs.ruleForm.resetFields()
            this.$popup({msg:'支付密码设置成功~',type:'success'})
          }
        }
      })
    },
    // 提现按钮
     withdrawalFn(){
      let p=this.$parent.withdrawV2
       p.pwd=md5(this.CashOut)
       withdrawV2(p).then(res=>{
        if(res.code===0){
          this.$popup({msg:'提现成功~',type:'success'})
          this.CashOut=''
          this.dialogVisible=false;
          let q={
            bankName:this.parent.card.bankName,
            bankCard:this.parent.card.bankCard,
            money:this.parent.appIncomeDetailsEntity.money,
            url:this.parent.headerImg || ''
          }
          this.$router.push({path:'/feedbackpage',query:{data:JSON.stringify(q)}})
        }else {
          this.$popup({msg:res.msg})
        }
      })
    },
    //提交按钮
    submit() {
     if(this.paymentStatus===0){
       this.paymentStatus=2
     }else if(this.paymentStatus===1){
       this.withdrawalFn()
     }else if(this.paymentStatus===2){
       this.SubmitSMS()
     }else {
       this.undataPassword()
     }
    }
  },
  watch: {
    userMsg(val) {
      this.userMsg = val
      let phone = val.mobile.split('')
      phone.splice(3, 4, '****')
      this.input3 = phone.join('')
    },
    appIncomeDetailsEntity(val) {
      this.appIncomeDetailsEntity = val;
    },
    dialogVisible(newVal){
     if(!newVal){
      this.input4=''
       this.ruleForm.newPas1=''
       this.ruleForm.newPas2=''
       //提现密码
       this.CashOut=''
     }
},
  }
}
</script>

<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.big ::v-deep .el-dialog {
  .el-dialog__header {
    padding: 0;
    margin: 0;

    .header-title {
      padding-top: 30px;
      font-size: 18px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: center;
      color: #222222;
    }
  }

  .el-dialog__body {
    padding: 0;

    .pay-conter_0 {
      height: 125px;
      line-height: 125px;
      text-align: center;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #333333;
    }

    .pay-conter_1 {
      padding: 0 40px;
      box-sizing: border-box;

      .pay {
        height: 180px;

        li:nth-child(1) {
          display: flex;
          justify-content: space-between;
          padding-top: 40px;
          margin-bottom: 30px;

          span {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #666666;
          }

          .money {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Semibold;
            font-weight: 600;
            text-align: LEFT;
            color: #333333;
          }
        }
      }
    }

    .pay-conter_2 {
      height: 182px;
      padding: 0px 40px;
      box-sizing: border-box;

      > div:nth-child(1) {
        padding-top: 35px;

        .el-input__inner {
          padding-left: 40px;
        }

        .el-input__inner, .el-input__prefix {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #999999;
        }

        .el-input__prefix {
          display: flex;
          align-items: center;
        }
      }

      > div:nth-child(2) {
        margin-top: 30px;

        .el-input__inner {
          padding-left: 10px;
        }
      }

      .el-input__suffix {
        display: flex;
        align-items: center;

        .suffix {
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #ff004d;
          cursor: pointer;
        }
      }
    }

    .pay-conter_3 {
      height: 218px;
      padding: 0 40px;
      box-sizing: border-box;

      > .title {
        padding-top: 40px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Medium;
        font-weight: 500;
        text-align: LEFT;
        color: #333333;
      }

      .el-input {
        margin-top: 20px;
      }
    }
  }

  .el-dialog__footer {
    padding: 0;

    .footer-but {
      padding-bottom: 40px;
      text-align: center;

      .el-button {
        width: 160px;
        height: 40px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #333333;
      }

      .but {
        background: #ff004d;
        color: #ffffff;
        margin-left: 40px;
      }
    }

  }
}
</style>
