<template>
  <div class="big" v-if="tableData.length>0">
    <el-table
        v-if="$store.state.user.webLoginType===0"
        :data="tableData"
        :show-header="false"
        class="customer-table"
        @row-click="rowClick"
        :row-class-name="tableRowClassName"
        :row-style="rowStyle"
        style="width:50%"

    >
      <el-table-column
          :show-overflow-tooltip="true"
          prop="createTime">
        <template slot-scope="scope">
          <div>{{ scope.row.createTime|time }}</div>
        </template>
      </el-table-column>
      <el-table-column
          :show-overflow-tooltip="true"
          prop="b">
        <template slot-scope="scope">
          <div>总额<span class="money">￥{{$toFindexFn(scope.row.totalMoney)}}</span></div>
        </template>
      </el-table-column>
      <el-table-column
          :show-overflow-tooltip="true"
          prop="c">
        <template slot-scope="scope">
          <div style="width: 100%">税费<span class="money">￥{{$toFindexFn(scope.row.faxMoney)}}</span></div>
        </template>
      </el-table-column>
      <el-table-column
          :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <div>应到账<span class="money">￥{{$toFindexFn(scope.row.money)}}</span></div>
        </template>
      </el-table-column>
      <el-table-column
          prop="e">
        <template slot-scope="scope">
          <button class="but" :class="scope.row.invoice.length===0&&scope.row.totalMoney>=500?'active1':'active2'">
            <img
                src="../../../assets/income/CashWithdrawal.png"
                v-if="scope.row.totalMoney>=500 && scope.row.invoice.length > 0 ">
            <img
              src="../../../assets/warning.png"
              v-if="scope.row.totalMoney>=500 && scope.row.invoice.length === 0 ">{{ stateFn(scope.row)}}
          </button>
        </template>
      </el-table-column>
    </el-table>
    <div class="right">
      <p class="title">
        发票信息
      </p>
      <div class="nav">
       <!--机构-->
        <ul class="information" v-if="this.tableData.length>0&&$store.state.user.webLoginType===0">
          <li class="company">{{this.tableData[this.currentIndex].paymentCompany}}</li>
          <li class="type">
            <div>
              <span class="label">开票金额：</span>
              <span class="content">￥{{$toFindexFn(this.tableData[this.currentIndex].totalMoney)}}</span>
            </div>
            <div>
              <span class="label">开票类型：</span>
<!--              <span class="content">{{filType(this.tableData[this.currentIndex].type)}}</span>-->
              <span class="content">{{ this.InvoiceHeader.receiptName ? (this.InvoiceHeader.receiptType === 1 ? '增值税发票' : '普通发票') : '无'}}</span>
            </div>
          </li>
        </ul>
        <!--个人-->
        <ul class="information" v-if="$store.state.user.webLoginType===1 &&appIncomeDetailsEntity!==undefined">
          <li class="company">{{appIncomeDetailsEntity.paymentCompany}}</li>
          <li class="type">
            <div>
              <span class="label">开票金额：</span>
              <span class="content">￥{{$toFindexFn(appIncomeDetailsEntity.totalMoney)}}</span>
            </div>
            <div>
              <span class="label">开票类型：</span>
<!--              <span class="content">{{filType(appIncomeDetailsEntity.type)}}</span>-->
              <span class="content">{{ this.InvoiceHeader.receiptName ? (this.InvoiceHeader.receiptType === 1 ? '增值税发票' : '普通发票') : '无'}}</span>
            </div>
          </li>
        </ul>
        <div class="but">
          <button @click="InvoiceHeaderfn()">发票抬头</button>
          <button class="butCopy"  :data-clipboard-text="copyData" >复制</button>
        </div>
      </div>
        <!--个人-->
      <div class="radio" v-if="$store.state.user.webLoginType===0&&tableData[currentIndex].totalMoney>=500">
        <el-radio v-model="tableData[currentIndex].radio" :disabled="radioStamp" label="1">上传电子发票</el-radio>
        <el-radio v-model="tableData[currentIndex].radio" :disabled="radioElectron" label="2">邮寄发票</el-radio>
      </div>
         <!-- 机构-->
      <div class="radio"  v-if="$store.state.user.webLoginType===1 && appIncomeDetailsEntity.totalMoney>=500" >
        <el-radio v-model="radio"  :disabled="radioStamp" label="1">上传电子发票</el-radio>
        <el-radio v-model="radio" :disabled="radioElectron" label="2">邮寄发票</el-radio>
      </div>
      <ul class="userinfo" v-if="$store.state.user.webLoginType===0?tableData[currentIndex].radio==='2':radio==='2'">
        <li><span class="label">收件人</span>：<span>{{InvoiceHeader.receiptConsignee}}</span></li>
        <li><span class="label">电话</span>：<span>{{InvoiceHeader.receiptTel}}</span></li>
        <li><span class="label">地址</span>：<span>{{InvoiceHeader.receiptAddress}}</span></li>
      </ul>
      <div class="upload" v-if="uploadHide">
        <el-upload
           class="upload-demo"
            :action="uploadApi"
            :headers="{...token, fileType:'image'}"
            :show-file-list="false"
            :before-upload="beforeUpload"
             :on-success="onSuccess"
        >
          <div class="uploadbg" v-if="undataHide">
            <img src="../../../assets/upload.png">
            <p>{{tableData[currentIndex].radio==="1"?'点击上传':'请上传快递凭证'}}</p>
          </div>
        </el-upload>
        <ul class="pictures" v-if="$store.state.user.webLoginType===0">
          <li v-for="(item,index) in  tableData[currentIndex].invoice" :key="index">
            <span @click="deleteFn(index)" ></span>
            <img :src="item">
          </li>
        </ul>
        <ul class="pictures" v-else>
          <li v-for="(item,index) in organInvoice" :key="index">
            <span @click="deleteFn(index)"></span>
            <img :src="item">
          </li>
        </ul>
      </div>
      <p v-if="uploadHide" class="footer-title">{{tableData[currentIndex].radio==="1"?'请根据付款公司的发票抬头开提现发票':'请上传您邮寄出的清晰的快递单号'}}</p>
    </div>
    <invoice-header ref="invoice"></invoice-header>
    <div style="clear:both"></div>
    <div class="footer-but">
      <el-button @click="$router.back()" >返回</el-button>
      <el-button  type="primary"  @click="submit()" class="but">确认提现</el-button>
    </div>
    <Payment ref="payment" :userMsg="usermsgObj" :appIncomeDetailsEntity="appIncomeDetailsEntity"></Payment>
  </div>
</template>

<script>
import InvoiceHeader from './InvoiceHeader';
import Payment from  './Payment'
import Clipboard from "clipboard";
import moment from 'moment'
import {dictInfo} from "../../../api/dictionary";
import {usermsg} from  "../../../api/index"
import {getRiseByIncome} from "../../../api/income";
 export default {
  name: "cashOutTable",
  components:{
    Payment,
    InvoiceHeader
  },
   computed:{
    //设置最多只能显示3张
    undataHide(){
      if(this.$store.state.user.webLoginType===0){
        return this.tableData[this.currentIndex].invoice.length <= 2;
      }else {
        return this.organInvoice.length <= 2;
      }
    },
  // 上传框是否隐藏
    uploadHide(){
      if(this.$store.state.user.webLoginType===0){
          return this.tableData[this.currentIndex].totalMoney >= 500;
      }else {
            return true;
      }
    },
  // 是否可选择上传电子发票和邮寄发票，当已经有上传的电子发票时就不能切换
    radioStamp(){
      if(this.$store.state.user.webLoginType===0){
        if(this.tableData[this.currentIndex].invoice.length===0){
          return false
        }
         if(this.tableData[this.currentIndex].invoice.length>0&&this.tableData[this.currentIndex].radio==="2"){
           return true
         }else {
           return false
         }
      }else {
        if(this.organInvoice.length>0){
          return  false
        }
          if(this.organInvoice.length>0 && this.radio==="2"){
            return true
          }else {
            return false
          }
      }
    },
    radioElectron(){
      if(this.$store.state.user.webLoginType===0){
        if(this.tableData[this.currentIndex].invoice.length===0){
          return  false
        }
        if(this.tableData[this.currentIndex].invoice.length>0&&this.tableData[this.currentIndex].radio==="1"){
          return true
        }else {
          return false
        }
      }else {
        if(this.organInvoice.length===0){
          return false
        }
        if(this.organInvoice.length>0 && this.radio==="1"){
          return true
        }else {
          return false
        }
      }
    },
  },
  data() {
    return {
      radio: "1",
      tableData: [],
      appIncomeDetailsEntity:{
      },
      clipboard:'',
      currentIndex:0,
      currentId:'',
      sunflowerCollection:[],
    //  复制内容
      copyData:'',
    // 发票抬头
      InvoiceHeader:{
        postAddress:'',
        postPhone:'',
        receiptAddress:'',
        receiptBank:'',
        receiptBankNo:'',
        receiptConsignee:'',
        receiptName:'',
        receiptNo:'',
        receiptTel:'',
        receiptType:''
      },
      //上传地址
      uploadApi: process.env.VUE_APP_BASE_API + '/api/file/upload',
      token:{'X-EFRESH-SECRET':this.$store.state.secret,'X-EFRESH-SESSION':this.$store.state.session},
    // 机构发票
      organInvoice:[],
      usermsgObj:{},
    // 提现数据
      withdrawV2:{
        withdrawRequestV2VoList:[]
      },
    }
  },
  created() {
    //获取type
    this.dict_dictInfo()
    this.usermsgfn()
  },
   mounted() {
    this.copy()
   },
   methods: {
    stateFn(val) {
      if(val.totalMoney<=500){
        return '无需发票'
      } else if(val.invoice.length ===0) {
        return '未上传'
      }else{
        return '已上传'
      }
    },
    rowClick(row) {
      this.currentId=row.id;
       this.tableData.forEach((item,index)=>{
          if(row.id===item.id){
            this.currentIndex=index;
          }
      })
    },
    copy(){
       this.clipboard=new Clipboard('.butCopy')
      this.clipboard.on('success',()=>{
        // console.log('复制成功')
        this.$popup({msg:'复制成功~'})
      })
      this.clipboard.on('error',()=>{
        console.log('复制失败')
      })
    },
  //  发票抬头
    InvoiceHeaderfn(){
      this.$refs.invoice.dialogVisible=true;
      //发票抬头
      this.$refs.invoice.InvoiceHeader=this.InvoiceHeader

    },
  //  账单中的公司抬头信息
   async  getRiseByIncomeFn(){
    let res=await getRiseByIncome({},{id:this.appIncomeDetailsEntity.id})
      if(res.code===0&&res.data!==null){
        this.InvoiceHeader=res.data
      }
     this.copyData = `
单位名称  ${this.InvoiceHeader.receiptName ? this.InvoiceHeader.receiptName : ''}
税号  ${this.InvoiceHeader.receiptName ? this.InvoiceHeader.receiptNo : ''}
单位地址  ${this.InvoiceHeader.receiptName ? this.InvoiceHeader.receiptAddress : ''}
电话号码  ${this.InvoiceHeader.receiptName ? this.InvoiceHeader.receiptTel : ''}
开户银行  ${this.InvoiceHeader.receiptName ? this.InvoiceHeader.receiptBank : ''}
银行账户  ${this.InvoiceHeader.receiptName ? this.InvoiceHeader.receiptBankNo : ''}
发票类型  ${this.InvoiceHeader.receiptName ? (this.InvoiceHeader.receiptType === 1 ? '增值税发票' : '普通发票') : ''}
`
    },
  // 收入分类
    async dict_dictInfo(){
     let res= await dictInfo({codes:['10016']})
      this.sunflowerCollection=res.data
    },
    filType(val){
      if(this.sunflowerCollection!==undefined){
          let r = this.sunflowerCollection.find(item=>{
            if(item.label===val) return item.value
          })
        //解决r为空是浏览器报错
        if(r!==undefined){
          return r.value
        }
      }
    },
    //文件上传前
    beforeUpload(file){
       if (file.type.split('/')[0]==='image'){
             return true
       }else {
         this.$popup({msg:'请上传正确的文件~'})
          return false
       }
    },
    //文件上传成功
    onSuccess(response){
      if(response.code===0) {
        if (this.$store.state.user.webLoginType === 0) {
          this.tableData[this.currentIndex].invoice.push(response.url)
          this.tableData = [...this.tableData]
        } else {
          this.organInvoice.push(response.url)
        }
      }else {
         this.$popup({msg:response.msg})
      }
    },
    //获取usermsg
    async usermsgfn(){
       let res= await usermsg()
       this.usermsgObj=res.data
    },
    //删除发票凭证
    deleteFn(index){
      if(this.$store.state.user.webLoginType===0){
        this.tableData[this.currentIndex].invoice.splice(index,1)
        this.tableData=[...this.tableData]
      }else {
       this.organInvoice.splice(index,1)
      }
    },
    // 表格样式
    tableRowClassName({row}){
      //给初始值
      if(row.totalMoney<=500){
         return 'warning-row'
      }

    },
  //点击高亮样式
    rowStyle({row}){
       if(row.id===this.currentId){
         return {'background':'#f8f8f8'}
       }
    },
  //提交
    submit(){
      this.withdrawV2.id=this.appIncomeDetailsEntity.id;
      this.withdrawV2.money=this.appIncomeDetailsEntity.totalMoney;
      this.withdrawV2.tax=this.appIncomeDetailsEntity.faxMoney;
      if(this.$store.state.user.webLoginType===0){
       // 判断发票是否上传
       let flag=  this.tableData.some(item=>{
           return item.totalMoney>500&&item.invoice.length<1
         })
        if(flag){
          return  this.$popup({msg:'您还有发票信息未上传'})
        }
       // 获取发票
        let withdrawRequestV2VoList=[]
        this.tableData.forEach(item=>{
          if( item.totalMoney>500){
            withdrawRequestV2VoList.push({id:item.id,invoiceUrl:item.invoice.join(',')})
          }
          this.withdrawV2.withdrawRequestV2VoList=withdrawRequestV2VoList;
        })
      if(this.usermsgObj.isPayPassword===0){
         this.$refs.payment.dialogVisible=true;
         this.$refs.payment.paymentStatus=0;
      }else {
        this.$refs.payment.paymentStatus=1;
        this.$refs.payment.dialogVisible=true
      }
      }else {
        //判断是否需要上传发票，机构
        if(this.organInvoice.length<=0){
          return  this.$popup({msg:'您还有发票信息未上传'})
        }

        this.withdrawV2.withdrawRequestV2VoList=[{id:this.appIncomeDetailsEntity.id,invoiceUrl:this.organInvoice.join(',')}]
        this.$refs.payment.dialogVisible=true;
        this.$refs.payment.paymentStatus=0;
         if(this.usermsgObj.isPayPassword===0){
           this.$refs.payment.dialogVisible=true;
           this.$refs.payment.paymentStatus=0;
         }else {
           this.$refs.payment.dialogVisible=true;
           this.$refs.payment.paymentStatus=1;
         }
      }
    }
  },
  filters:{
    time(val){
       if(val !==undefined){
         let date =  moment(val).format('L')
         date=date.split('/')
         let month=date[0]
         let year=date[2]
         return year+'年'+month+'月'
       }
    },
  },
  beforeDestroy() {
   if(this.clipboard!==''){
     this.clipboard.destroy()
   }
  },
  watch:{
    tableData(val){
    // 赋初始值
      this.currentId=val[0].id
    },
    appIncomeDetailsEntity(newVal){
      this.appIncomeDetailsEntity=newVal
         this.getRiseByIncomeFn()
    }

  }
}
</script>
<style>
.el-table .warning-row {
  background:#edffee;
}
</style>
<style scoped lang="scss">
* {
  margin: 0;
  padding: 0;
}

.big {
  ::v-deep .el-table {
    float: left;
    .el-table__row {
      cursor: pointer;
      height: 60px;
      .el-table__cell {
        padding: 0;
        border: none;
        .cell {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #666666;
          .money {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 500;
            text-align: LEFT;
            color: black;
            display: inline-block;
            box-sizing: border-box;
            padding: 0 3px 0 0;
            width:69px;
            white-space: nowrap;
            /*溢出部分文字隐藏*/
            overflow: hidden;
            /*溢出部分省略号处理*/
            text-overflow: ellipsis;
            vertical-align: middle;
          }

          .but {
            width: 80px;
            height: 28px;
            border-radius: 90px;
            border: none;
            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Semibold;
            font-weight: 600;
            color: #ff5c00;

            img {
              width: 14px;
              height: 14px;
              vertical-align: middle;
              margin-right: 4px;
            }
          }

          .active1 {
            color: #ff5c00;
            background: #fdebd0;
          }

          .active2 {
            color: #1b5821;
            background: #ddfddf;
          }
        }
      }
    }
  }
  .right {
    float: left;
    width: 50%;
    background: #f8f8f8;
    padding: 18px 32px 0 30px;
    box-sizing: border-box;
    min-height: 347px;

    > title {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Semibold;
      font-weight: 600;
      text-align: LEFT;
      color: #333333;
    }

    .nav {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      padding-bottom: 22px;
      border-bottom: 1px solid #f1f1f1;

      > .information {
        list-style: none;

        .company {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
        }

        .type {
          margin-top: 12px;
          display: flex;

          > div {
            margin-right: 44px;
          }
        }

        span {
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
        }

        .label {
          color: #999999;
        }

        .content {
          color: black;
        }
      }

      .but {
        font-size: 0;

        button {
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #ff004d;
          width: 89px;
          height: 31px;
          background: #ffffff;
          border: 1px solid #f0f0f0;
          cursor: pointer;
        }

        button:nth-child(2) {
          width: 65px;
          height: 31px;
          margin-left: 11px;
        }
      }
    }

    .radio {
      margin-top: 24px;
      margin-bottom: 20px;

      ::v-deep .el-radio {
        margin-right: 60px;

        .el-radio__label {
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #333333;
        }
      }
    }

    .userinfo {
      list-style: none;
      li:nth-child(2){
       margin: 10px 0;
      }
      > li {
        font-size: 12px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #666666;
        .label{
          width: 50px;
          display: inline-block;
          text-align-last: justify;
          vertical-align: top;
        }
      }
    }
    .upload{
      margin:21px 0 10px 0;
      //display: flex;
    .upload-demo{
      .uploadbg{
        width: 140px;
        height: 100px;
        background: #FFFFFF;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
         width: 16px;
          height: 16px;
        }
        p{
          font-size: 12px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #ff004d;
          margin-bottom: 11px;
        }
      }
    }
      .pictures{
        list-style: none;
        display: flex;
        li{
          position: relative;
          width: 140px;
          height: 100px;
          margin: 0px 15px;
          span{
            position: absolute;
            right: -8px;
            top:-8px;
            display: inline-block;
            width: 16px;
            height: 16px;
            cursor: pointer;
            background-image:url("../../../assets/WithdrawalClose.png");
            background-size: 100% 100%;
          }
          img{
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .footer-title{
      font-size: 12px;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: LEFT;
      color: #999999;
      margin-bottom: 24px;
    }
  }
  .footer-but{
    padding-top:64px;
    text-align: center;
    .el-button{
      width: 160px;
      height: 50px;
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      background: #f4f4f4;
      border-radius: 3px;
    }
    .but{
      margin-left: 40px;
      background: #ff004d;
    }
  }
}


</style>
